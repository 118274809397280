import React, { FC, useState } from 'react';
import { Button } from '@willeder/component-library';
import { useTranslation } from 'react-i18next';
import useFileOperations from 'hooks/spaceFiles/useFileOperations';
import userSpaceStore from 'store/userSpaceStore';
import { prepareAccordionFileData } from 'utils/helper';
import { ReactComponent as DownloadIcon } from '../../../assets/icons/download.svg';
import { ReactComponent as DownloadDeactive } from '../../../assets/icons/download_disable.svg';
import styles from './FileListLayout.module.scss';
import AccordionTable from 'components/molecules/AccordionTable';
import ConfirmModal from 'components/molecules/ConfirmModal';
import S3FileDownloader from './S3FileDownloader';
import downloadS3File from './helper';
import downloadSakuraStorageFile from './helper';
import FileEncryptionComponent from '../FileEncryptionComponent/FileEncryptionComponent';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import useSpaceFiles from 'hooks/spaceFiles/useSpaceFiles';

interface FileListProps {
  isReceiver?: boolean;
  spaceFiles: FileData[];
}

const FileListLayout: FC<FileListProps> = ({
  isReceiver = false,
  spaceFiles,
}) => {
  const { t, i18n } = useTranslation();
  const [selectAllChecked, setSelectAllChecked] = React.useState(false);
  const accordionItems = prepareAccordionFileData(
    spaceFiles,
    i18n?.language === 'ja' ? 'ja' : 'en'
  );


  const {
    onDownloadAllFiles,
    onDeleteAllFiles,
    onDeleteAllFilesNew,
    onDecryptFile,
    getSelectedItems,
    isLoading,
    findParentItem,
    onUpdateSelectedItems,
    selectedItems,
    isDeleteModal,
    setDeleteModal,
  } = useFileOperations(accordionItems);


  const handleSelectAllChange = (isChecked: boolean) => {
    setSelectAllChecked(isChecked);
    let selectedItems: string[] = [];
    accordionItems.forEach((item) => {
      // should be able to select all
      // if (item?.status === 'labels.off') return;
      let updatedSelectedItems = [...selectedItems];
      if (isChecked) {
        updatedSelectedItems.push(item.id);
        if (!!item?.children?.length) {
          item.children?.forEach((child: { id: string }) => {
            if (!updatedSelectedItems.includes(child.id)) {
              updatedSelectedItems.push(child.id);
            }
          });
        } else {
          const parentItem = findParentItem(accordionItems, item.id);
          if (
            parentItem &&
            parentItem.children?.every((child) =>
              updatedSelectedItems.includes(child.id)
            )
          ) {
            updatedSelectedItems.push(parentItem.id);
          }
        }
      } else {
        updatedSelectedItems = updatedSelectedItems.filter(
          (id) => id !== item.id
        );
        if (!!item?.children?.length) {
          item.children?.forEach((child: { id: string }) => {
            updatedSelectedItems = updatedSelectedItems.filter(
              (id) => id !== child.id
            );
          });
        } else {
          const parentItem = findParentItem(accordionItems, item.id);
          if (parentItem) {
            updatedSelectedItems = updatedSelectedItems.filter(
              (id) => id !== parentItem.id
            );
          }
        }
      }
      selectedItems = [...updatedSelectedItems];
    });

    onUpdateSelectedItems(selectedItems);
  };

  const onDownloadFiles = async () => {
    const selectedFiles = getSelectedItems();
  
    if (!selectedFiles || selectedFiles.length === 0) {
      console.error("No files selected for download");
      return;
    }
  
    // If there's only one file, download it directly
    if (selectedFiles.length === 1) {
      const file = selectedFiles[0];
      if (file.filePath) {
        try {
          const decryptedBlob = await downloadSakuraStorageFile({
            fileKey: file.filePath,
            title: file.title,
            cb: onDecryptFile,
          });
  
          if (decryptedBlob) {
            const fileURL = URL.createObjectURL(decryptedBlob);
            const link = document.createElement("a");
            link.href = fileURL;
            link.download = file.title || "untitled";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(fileURL);
            console.log("File downloaded successfully");
          }
        } catch (error) {
          console.error(`Error downloading the file: ${file.title}`, error);
        }
      } else {
        console.error("File path is missing for the selected file");
      }
      return;
    }
  
    // If there are multiple files, create a ZIP
    const zip = new JSZip();
  
    for (const file of selectedFiles) {
      if (file.filePath) {
        try {
          const decryptedBlob = await downloadSakuraStorageFile({
            fileKey: file.filePath,
            title: file.title,
            cb: onDecryptFile,
          });
  
          if (decryptedBlob) {
            zip.file(file.title || "untitled", decryptedBlob);
          }
        } catch (error) {
          console.error(`Error downloading the file: ${file.title}`, error);
        }
      } else {
        console.error("File path is missing for the selected file");
      }
    }
  
    try {
      const zipBlob = await zip.generateAsync({ type: "blob" });
      const generateFileName = () => {
        const now = new Date();
        const formattedDate = now.toISOString().replace(/[:.]/g, "-"); // Replace invalid characters
        return `${formattedDate}.zip`;
      };
      const fileName = generateFileName();
      saveAs(zipBlob, fileName);
      console.log("ZIP file downloaded successfully");
    } catch (error) {
      console.error("Error creating ZIP file", error);
    }
  };
  
  

  // TODO: cleanup unnecessary button animation uk
  return (
    <div className={styles.wrapper}>
      <div className={styles.dragWrapper}>
        <div className={styles.dragDropBox}>
          <div className={styles.buttonWrapper}>

            <div className={styles.animateBtnWrapper}>
              <div
                className={`${styles.borderGlow} ${selectedItems.length ? styles.withItems : ''}`}
              ></div>
              <Button
                className={`download ss-btn ${selectedItems.length ? styles.animate : ''} ${selectedItems.length ? styles.glowing : ''}`}
                icon={
                  selectedItems.length ? <DownloadIcon /> : <DownloadDeactive />
                }
                isLoading={isLoading}
                iconPosition="right"
                disabled={!selectedItems.length || isLoading}
                onClick={() => onDownloadFiles()}
                // onClick={() => onDownloadAllFiles()}

              >
                {t('buttons.download')}
              </Button>
            </div>
            {!isReceiver && (
              <Button
                className="cancelButton"
                onClick={() => setDeleteModal(true)}
                disabled={!selectedItems.length || isLoading}
              >
                {t('buttons.delete')}
              </Button>
            )}
          </div>
          <AccordionTable
            showReceiverAccordion={isReceiver}
            handleSelectAllChange={handleSelectAllChange}
            selectAllChecked={selectAllChecked}
            items={accordionItems}
            header={[
              t('labels.fileName'),
              t('labels.size'),
              t('labels.status'),
              t('labels.activateAt'),
              '',
            ]}
          />
        </div>
      </div>
      <ConfirmModal
        title={t('labels.deleteFiles')}
        content={<p>{t('labels.deleteFileConfirmation')}</p>}
        cancel={{
          children: t('buttons.no'),
          onClick: () => setDeleteModal(false),
        }}
        confirm={{
          children: t('buttons.yes'),
          onClick: () => onDeleteAllFilesNew(),
        }}
        isOpen={isDeleteModal}
      />
    </div>
  );
};

export default FileListLayout;
