import { Upload } from '@aws-sdk/lib-storage';
import { v4 as uuidv4 } from 'uuid';
const {
  S3Client,
  GetObjectCommand,
  DeleteObjectCommand,
} = require('@aws-sdk/client-s3');

export const s3Client = new S3Client({
  endpoint: process.env.REACT_APP_AWS_ENDPOINT,
  region: process.env.REACT_APP_AWS_REGION,
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
  },
});

export const uploadFiletoStorage = async (
  file: File,
  encryptedFile: any,
  onProgress?: (progress: number) => void,
  abortController?: AbortController
) => {
  try {
    const filepath = `postspace-restructuring/development/${uuidv4()}-${file.name}`;

    const uploader = new Upload({
      client: s3Client,
      params: {
        Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
        Key: filepath,
        Body: encryptedFile,
        ACL: 'public-read',
        ContentType: file.type,
      },
      queueSize: 4,
      partSize: 1024 * 1024 * 5,
      leavePartsOnError: false,
    });

    uploader.on('httpUploadProgress', (progress: any) => {
      console.log(progress);
      let percentage = Math.round((progress.loaded / progress.total) * 100);
      onProgress && onProgress(percentage);
    });

    if (abortController) {
      abortController.signal.onabort = () => uploader.abort();
    }

    const data = await uploader.done();
    return data.Key;
  } catch (e) {
    console.log(e);
    throw new Error('Failed to upload file');
  }
};

export const uploadFileWithPutCommand = async (
  file: File,
  index: number,
  encryptedFile: any,
  totalSize: number,
  files: FileWithProgress[],
  setFiles: (data: FileWithProgress[]) => void,
  setCombinedProgress: (progress: number) => void,
  abortController?: AbortController
) => {
  try {
    const filepath = `postspace-restructuring/development/${uuidv4()}-${file.name}`;
    console.log(`Preparing to upload file at path: ${filepath}`);

    const uploader = new Upload({
      client: s3Client,
      params: {
        Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
        Key: filepath,
        Body: encryptedFile,
        ACL: 'public-read',
        ContentType: file.type,
      },
      queueSize: 4,
      partSize: 1024 * 1024 * 5,
      leavePartsOnError: false,
    });

    uploader.on('httpUploadProgress', (progress: any) => {
      const progressPercentage = (progress.loaded / progress.total) * 100;
      const newFiles = files.map((file, idx) =>
        idx === index ? { ...file, progress: progressPercentage } : file
      );
      setFiles(newFiles);
      const totalUploadedPercentage = newFiles.reduce(
        (acc, curr) => acc + (curr.progress * curr.size) / totalSize,
        0
      );
      console.log(totalUploadedPercentage, '*****totalUploadedPercentage');
      setCombinedProgress(totalUploadedPercentage);

      if (progress.loaded === progress.total) {
        const updatedFiles = newFiles.map((file, idx) =>
          idx === index ? { ...file, uploaded: true } : file
        );
        setFiles(updatedFiles);
      }
    });

    if (abortController) {
      abortController.signal.onabort = () => uploader.abort();
    }

    const data = await uploader.done();
    return data.Key;
  } catch (e) {
    console.error('Upload failed:', e);
    throw new Error('Failed to upload file');
  }
};

export const deleteFileFromStorage = async (url: string) => {
  const bucketParams = {
    Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
    Key: url,
  };

  const deleteObjectCommand = new DeleteObjectCommand(bucketParams);

  try {
    const data = await s3Client.send(deleteObjectCommand);
    console.log('********* Success. Object deleted.', data);
    return data;
  } catch (err) {
    console.log('Error', err);
  }
};

export const downloadFilefromStorage = async (
  fileKey: string,
  fileName: string = 'file'
) => {
  const command = new GetObjectCommand({
    Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
    Key: fileKey,
  });

  try {
    const response = await s3Client.send(command);
    const str = await response.Body.transformToString();

    const blob = new Blob([str], { type: response.ContentType });
    const newFile = new File([blob], fileName);

    return newFile;
  } catch (err) {
    console.error(err);
  }
};

export const downloadFileFromStorageWithProgress = async (
  fileKey: string,
  fileName: string = 'file',
  onProgress: (progress: number) => void
) => {
  const command = new GetObjectCommand({
    Bucket: process.env.REACT_APP_AWS_BUCKET_NAME!,
    Key: fileKey,
  });

  try {
    const { Body, ContentType, ContentLength } = await s3Client.send(command);

    if (Body instanceof ReadableStream) {
      const reader = Body.getReader();
      let receivedLength = 0; // bytes received
      let chunks = []; // array of received binary chunks (composes the file)

      // Read the stream
      while (true) {
        const { done, value } = await reader.read();

        if (done) {
          break;
        }

        chunks.push(value);
        receivedLength += value.length;
        const progress = (receivedLength / ContentLength) * 100;
        console.log(
          `Received ${receivedLength} of ${ContentLength} bytes (${((receivedLength / ContentLength) * 100).toFixed(2)}%)`
        );
        onProgress(progress);
      }

      const blob = new Blob(chunks, { type: ContentType });
      const newFile = new File([blob], fileName, { type: ContentType });

      return newFile;
    } else {
      console.error('Download response body is not a readable stream.');
    }
  } catch (err) {
    console.error(err);
  }
};
