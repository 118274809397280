// @ts-nocheck

import { S3Client, PutObjectCommand } from '@aws-sdk/client-s3';
// import { useSpaceFile } from 'hooks/encryption/useSpaceFile';
import { s3Client } from 'services/awsFileUpload';
import { v4 as uuidv4 } from 'uuid';

export const encryptFiles = async (
  files: File[],
  encryptFileData: (file: File) => Promise<File>
): Promise<File[]> => {
  const encryptedFiles: File[] = [];
  for (const file of files) {
    const encryptedFile = await encryptFileData(file);
    encryptedFiles.push(encryptedFile);
  }
  return encryptedFiles;
};

const uploadFile = async (
  file: File,
  key: string,
  signal: AbortSignal
): Promise<string> => {
  try {
    const fileArrayBuffer = await file.arrayBuffer(); 

    const command = new PutObjectCommand({
      Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
      Key: key,
      Body: new Uint8Array(fileArrayBuffer),
    });


    await s3Client.send(command, { abortSignal: signal });
    console.log(`Uploaded: ${key}`);
    return key; // アップロード成功時にキーを返す
  } catch (err: any) {
    if (signal.aborted) {
      console.error(`Upload cancelled: ${key}`);
    } else {
      console.error(`Failed to upload ${key}:`, err);
    }
    throw err;
  }
};

export const uploadFilesToAWS = async (files: File[], signal: AbortSignal) => {
  if (files.length < 1) throw new Error('there are no files');

  const uploadedFiles: {
    fileName: string;
    filePath: string;
    fileSize: number;
  }[] = [];

  try {
    const promises = files.map(async (file) => {
      const filePath = `postspace-restructuring/development/${uuidv4()}-${file.name}`;
      console.log('Uploading to AWS:', filePath);

      await uploadFile(file, filePath, signal); // Signalを渡す
      uploadedFiles.push({
        fileName: file.name.replace('.enc', ''),
        filePath,
        fileSize: file.size,
      });
    });

    await Promise.all(promises); // Signalで途中停止可能
    return uploadedFiles;
  } catch (err: any) {
    if (signal.aborted) {
      console.log('Upload process cancelled');
    }
    console.log(err, 'error came from here');
    throw err;
  }
};
