import React, { useEffect, useState } from 'react';

// Type-safe sleep function with abort support
const sleep = (ms: number, signal?: AbortSignal): Promise<void> =>
  new Promise((resolve, reject) => {
    const timeout = setTimeout(() => resolve(), ms);

    if (signal) {
      signal.addEventListener('abort', () => {
        clearTimeout(timeout); // Clear the timeout
        reject(new DOMException('Aborted', 'AbortError'));
      });
    }
  });

const CancellableProcess: React.FC = () => {
  const [status, setStatus] = useState<string>('Idle');
  const [abortController, setAbortController] = useState<AbortController | null>(null);

  const startProcess = () => {
    const controller = new AbortController();
    setAbortController(controller);

    setStatus('Processing...');
    sleep(5000, controller.signal) // Sleep for 5 seconds
      .then(() => setStatus('Process completed!'))
      .catch((err) => {
        if (err.name === 'AbortError') {
          setStatus('Process cancelled!');
        } else {
          setStatus(`Error: ${err.message}`);
        }
      });
  };

  const cancelProcess = () => {
    if (abortController) {
      abortController.abort(); // Cancel the process
      setAbortController(null); // Reset the controller
    }
  };

  return (
    <div>
      <h1>Cancellable Process Example</h1>
      <p>Status: {status}</p>
      <button onClick={startProcess} disabled={status === 'Processing...'}>
        Start Process
      </button>
      <button onClick={cancelProcess} disabled={status !== 'Processing...'}>
        Cancel Process
      </button>
    </div>
  );
};

export default CancellableProcess;
