// @ts-nocheck
import { S3Client, GetObjectCommand } from '@aws-sdk/client-s3';

// import { S3Client, GetObjectCommand } from "@aws-sdk/client-s3";

export const downloadSakuraStorageFile = async ({
  fileKey,
  title,
  cb,
}: {
  fileKey: string; // Path to the file in the bucket
  title: string; // Desired file name
  cb: any; // Callback function for decryption
}): Promise<Blob | null> => {
  const endpoint = process.env.REACT_APP_AWS_ENDPOINT!;
  const bucketName = process.env.REACT_APP_AWS_BUCKET_NAME!;
  const region = process.env.REACT_APP_AWS_REGION!;
  const accessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY!;
  const secretAccessKey = process.env.REACT_APP_AWS_SECRET_KEY!;

  const s3Client = new S3Client({
    endpoint, // Use Sakura Storage's endpoint
    region,
    credentials: {
      accessKeyId,
      secretAccessKey,
    },
  });

  try {
    // Create the GetObjectCommand
    const command = new GetObjectCommand({
      Bucket: bucketName,
      Key: fileKey,
    });

    // Execute the command
    const response = await s3Client.send(command);

    if (!response.Body) {
      throw new Error("No file body returned from Sakura Storage.");
    }

    if (!(response.Body instanceof ReadableStream)) {
      throw new Error("Response Body is not a ReadableStream.");
    }

    // Convert ReadableStream to Blob
    const reader = response.Body.getReader();
    const chunks: Uint8Array[] = [];
    let totalLength = 0;

    while (true) {
      const { done, value } = await reader.read();
      if (done) break;
      if (value) {
        chunks.push(value);
        totalLength += value.length;
      }
    }

    const fullBlob = new Blob(chunks, { type: response.ContentType || "application/octet-stream" });
    console.log(fullBlob, "from sakura download");

    // Create a File object
    const downloadedFile = new File([fullBlob], title, { type: response.ContentType });

    // Decrypt the file using the callback
    const decryptedData = await cb(downloadedFile);

    if (decryptedData) {
      console.log("Decrypted file:", decryptedData);
      return new Blob([decryptedData], { type: response.ContentType });
    } else {
      console.error("Decryption failed.");
      return null;
    }
  } catch (error) {
    console.error("Error downloading file from Sakura Storage:", error);
    return null;
  }
};

// const downloadSakuraStorageFile = async ({
//   // endpoint,
//   // region,
//   // bucketName,
//   // accessKeyId,
//   // secretAccessKey,
//   fileKey,
//   title,
//   cb,
// }: {
//   // endpoint: string; // Sakura Storage endpoint (e.g., https://s3.example.com)
//   // region: string; // Region (e.g., "us-east-1")
//   // bucketName: string; // Bucket name
//   fileKey: string; // Path to the file in the bucket
//   // accessKeyId: string; // API access key
//   // secretAccessKey: string; // API secret key
//   title: string; // Desired file name
//   cb: any; // Callback function
// }) => {
//   // Initialize the S3 client with the custom endpoint
//   const endpoint = process.env.REACT_APP_AWS_ENDPOINT!;
//   const bucketName = process.env.REACT_APP_AWS_BUCKET_NAME!;
//   const region = process.env.REACT_APP_AWS_REGION!;
//   const accessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY!;
//   const secretAccessKey = process.env.REACT_APP_AWS_SECRET_KEY!;
//   const s3Client = new S3Client({
//     endpoint, // Use Sakura Storage's endpoint
//     region,
//     credentials: {
//       accessKeyId,
//       secretAccessKey,
//     },
//   });

//   try {
//     // Create the GetObjectCommand
//     const command = new GetObjectCommand({
//       Bucket: bucketName,
//       Key: fileKey,
//     });

//     // Execute the command
//     const response = await s3Client.send(command);
//     const { Body, ContentType, ContentLength } = await s3Client.send(command);
//     if (Body instanceof ReadableStream) {
//       const reader = Body.getReader();
//       let chunks = [];
//       while (true) {
//         const { done, value } = await reader.read();

//         if (done) {
//           break;
//         }

//         chunks.push(value);
//       }
//       const blob = new Blob(chunks, { type: ContentType });
//       console.log(blob, 'from sakura download');
//       const newFile = new File([blob], title, { type: ContentType });
//       console.log(newFile, "from sakura download")
//       const decryptedFile: any = await cb(newFile);
//       console.log(decryptedFile)
//       if (decryptedFile) {
//         // Create a blob URL for the decrypted file
//         const decryptedBlob = new Blob([decryptedFile], { type: ContentType });
//         const url = URL.createObjectURL(decryptedBlob);
      
//         // Create a temporary link element
//         const link = document.createElement('a');
//         link.href = url;
//         link.download = title; // Set the filename for the downloaded file
      
//         // Append the link to the document body (not necessary, but for safety)
//         document.body.appendChild(link);
      
//         // Programmatically click the link to start the download
//         link.click();
      
//         // Remove the link element and revoke the blob URL after the download
//         document.body.removeChild(link);
//         URL.revokeObjectURL(url);
//       }
//     }

//     // Convert the response body (stream) to a Blob
//     // if (response.Body) {
//     //   const blob = await streamToBlob(
//     //     response.Body as ReadableStream<Uint8Array>
//     //   );
//     //   const octetStreamBlob = new Blob([blob], {
//     //     type: 'application/octet-stream',
//     //   });

//     //   console.log(blob, 'from sakura download');

//     //   // Create a link to trigger the download
//     //   const link = document.createElement('a');
//     //   link.href = URL.createObjectURL(blob);

//     //   // Use the 'title' parameter for the file name
//     //   link.download = (title || fileKey.split('/').pop() || 'file') + '.enc';
//     //   document.body.appendChild(link);
//     //   link.click();
//     //   document.body.removeChild(link);

//     //   console.log('File downloaded successfully');
//     // } else {
//     //   console.error('No file body returned from Sakura Storage');
//     // }
//   } catch (error) {
//     console.error('Error downloading file from Sakura Storage:', error);
//   }
// };

// Helper function to convert stream to Blob
const streamToBlob = async (
  stream: ReadableStream<Uint8Array>
): Promise<Blob> => {
  const reader = stream.getReader();
  const chunks: Uint8Array[] = [];
  let done = false;

  while (!done) {
    const { value, done: streamDone } = await reader.read();
    if (value) {
      chunks.push(value);
    }
    done = streamDone;
  }

  return new Blob(chunks);
};

export default downloadSakuraStorageFile;
