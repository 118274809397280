const Table = ({ files }: { files: File[] }) => {
  return (
    <div>
      <table>
        <thead>
          <tr>
            <td>ファイル名</td>
            <td>タイプ</td>
            <td>ファイルサイズ</td>
            <td>最終更新時刻</td>
          </tr>
        </thead>
        <tbody>
          {files.map((file, index) => (
            <tr key={index}>
              <td>{file.name}</td>
              <td>{file.type}</td>
              <td>{file.size}</td>
              <td>{file.lastModified}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
