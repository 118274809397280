// @ts-nocheck

import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import './Modal.css'; // Import a CSS file for styling
import { encryptFiles, uploadFilesToAWS } from './helper';
import { useSpaceFile } from 'hooks/encryption/useSpaceFile';
import { useUploadFile } from './useUploadFile';
import { useUploadFilesToAWS } from './useUploadFilesToAWS';
import { ReactComponent as DragDropIcon } from '../../../assets/icons/drag-drop.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete.svg';

import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Button } from '@willeder/component-library';
import instance from 'utils/axios';
import axios from 'axios';
import useSpaceFiles from 'hooks/spaceFiles/useSpaceFiles';

interface FileUploadModalProps {
  isOpen: boolean;
  onClose: () => void;
  onFilesSelected: (files: File[]) => void;
  spaceId: string | null;
}

const FileUploadModal: React.FC<FileUploadModalProps> = ({
  isOpen,
  onClose,
  onFilesSelected,
  spaceId,
}) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const [dragging, setDragging] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const { encryptFileData } = useSpaceFile();
  const { t } = useTranslation();
  // const [abortController, setAbortController] =
  //   useState<AbortController | null>(null);
  const [isCanceled, setIsCanceled] = useState(false);
  const { getSpaceFiles, getStorageInfo } = useSpaceFiles();

  // const [abortControllers, setAbortControllers] = useState<
  //   Record<string, AbortController>
  // >({});
  // const { uploadFileToDB } = useUploadFile();
  // const {
  //   // mutate: UploadFilesToAWS,
  //   isLoading,
  //   isError,
  //   isSuccess,
  //   data,
  //   cancelUpload,
  // } = useUploadFilesToAWS();

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isOpen]);

  const handleOutsideClick = (event: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      onClose();
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragging(false);
    const files = Array.from(event.dataTransfer.files);
    setFiles((prevFiles) => [...prevFiles, ...files]);
    onFilesSelected(files);
  };

  const deleteItem = (indexToRemove: number) => {
    setFiles((prevItems) =>
      prevItems.filter((_, index) => index !== indexToRemove)
    );
  };

  const onSelectFileFromLocal = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!event.target.files) {
      console.error('Files are not selected on onSelectFileFromLoacl');
      return;
    }

    console.log('files are selected');

    setFiles([...files, ...event.target.files]);
    event.target.value = '';
  };

  type FileInfo = {
    fileName: string;
    filePath: string;
    fileSize: number;
  }[];

  const saveFilesInfoToDB = async (data: {
    spaceId: string;
    files: FileInfo;
    encryptedSecretCode: string;
  }) => {
    try {
      const cancelTokenSource = axios.CancelToken.source();

      const response = await instance.post(
        `/user/spaceFile/${spaceId}`,
        {
          files: data.files,
          encryptedSecretCode: data.encryptedSecretCode,
        }, // JSON payload
        {
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
          },
          cancelToken: cancelTokenSource.token, // Attach cancel token
        }
      );
      return Promise.resolve(response.data);
    } catch (err) {
      return Promise.reject(err);
    }
  };

  const abortController = useRef<AbortController | null>(null);
  const mutation = useMutation(saveFilesInfoToDB);

  const onClickUploadFiles = async () => {
    try {
      if (files.length < 1) {
        console.error('Files are not selected on onClickUploadFiles');
        return;
      }

      console.log('Processing...');
      abortController.current = new AbortController();
      const signal = abortController.current.signal;

      try {
        await new Promise((resolve) => setTimeout(resolve, 0));

        // Add all TODO

        // TODO 1. encrypt files
        const encryptedFiles = await encryptFiles(files, encryptFileData);
        // TODO 2. upload to aws
        const uploadFilesInfo: FileInfo = await uploadFilesToAWS(
          encryptedFiles,
          signal
        );
        console.log(uploadFilesInfo);

        // TODO 3. add uploaded details to db(backend server)

        const data = {
          spaceId: spaceId || '',
          files: uploadFilesInfo,
          encryptedSecretCode: '',
        };

        const result = await mutation.mutateAsync(data);
        getSpaceFiles();
        getStorageInfo();
        onClose()
        resetFiles()

        console.log('Completed!');
        return Promise.resolve(result);
      } catch (err: any) {
        if (err.name === 'AbortError') {
          console.log('Upload cancelled');
        } else {
          console.error('Error during upload:', err);
        }
      }
    } catch (err) {
      console.error('Unexpected error:', err);
    }
  };

  const handleCancel = () => {
    if (abortController.current) {
      abortController.current.abort();
      abortController.current = null;
      console.log('Upload cancelled by user');
      onClose();
    }
  };

  const resetFiles = () => setFiles([]);

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className="modal-overlay">
      <div
        className={`modal-content file-upload-modal ${dragging ? 'dragging' : ''}`}
        ref={modalRef}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        {/* {spaceId && <p>URL Parameter: {spaceId}</p>} */}
        {files.length === 0 ? (
          <>
            <DragDropIcon />
            <p className="selectFiles">{t('labels.selectOrDropFiles')}</p>

            <input
              type="file"
              multiple
              onChange={onSelectFileFromLocal}
              style={{ display: 'none' }}
              id="file-upload-input"
            />
            <label htmlFor="file-upload-input" className="file-upload-button">
              {t('buttons.select')}
            </label>
            <Button
              className="cancelButton mw280 alterText"
              onClick={(e) => {
                onClose();
              }}
            >
              {t('buttons.cancel')}
            </Button>
          </>
        ) : null}
        {files.length > 0 ? (
          <>
            <p className="selectFilesSM">{t('labels.selectOrDropFiles')}</p>
            <input
              type="file"
              multiple
              onChange={onSelectFileFromLocal}
              style={{ display: 'none' }}
              id="file-upload-input"
            />
            <label htmlFor="file-upload-input" className="file-upload-button">
              {t('buttons.select')}
            </label>
            <div className="selected-files">
              <h3>Selected Files:</h3>
              <ul
                style={{
                  maxHeight: '200px', // Set the max height
                  overflowY: 'auto', // Enable vertical scrolling
                  border: '1px solid #ccc', // Optional: Add a border for visibility
                  padding: '10px', // Optional: Add some padding
                }}
              >
                {files.map((file, index) => (
                  <div key={index} className="selectedFileItem">
                    <li>{file.name}</li>
                    <DeleteIcon
                      onClick={(e) => {
                        e.stopPropagation();
                        deleteItem(index);
                      }}
                    />
                    {/* <button onClick={() => deleteItem(index)}>Delete</button> */}
                  </div>
                ))}
              </ul>
            </div>
            <div className="buttonWrapper">
              <Button
                className="selectButton capitalize"
                onClick={onClickUploadFiles}
              >
                Upload
              </Button>
              <Button
                className="cancelButton mw280 alterText"
                onClick={(e) => {
                  e.stopPropagation();
                  handleCancel();
                  onClose();
                  resetFiles();
                }}
              >
                {t('buttons.cancel')}
              </Button>
              {/* <button onClick={handleCancel}>Cancel</button> */}
              {/* <button onClick={resetFiles}>Reset</button> */}
              {/* <Button className="selectButton capitalize" onClick={resetFiles}>
                Reset
              </Button> */}
            </div>
          </>
        ) : null}

        {isCanceled && <p>Upload process was canceled.</p>}
        {/* {isLoading && <p>Uploading file...</p>}
        {isSuccess && <p>File uploaded successfully!</p>} */}
      </div>
    </div>,
    document.body
  );
};

export default FileUploadModal;
