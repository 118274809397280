import DragDropModal from 'components/molecules/DragDropModal';
import React, { useEffect, useState } from 'react';
import Table from './Table';
import { encryptFiles, uploadFilesToAWS } from './helper';

const Temp = () => {
  const [files, setFiles] = useState<File[]>([]);
  const [abortControllers, setAbortControllers] = useState<
    Record<string, AbortController>
  >({});

  useEffect(() => {
    console.log('temp loaded');
  }, []);

  const a = {};
  const onSelectFileFromLoacl = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!event.target.files) {
      console.error('Files are not selected on onSelectFileFromLoacl');
      return;
    }

    console.log('files are selected');

    setFiles([...files, ...event.target.files]);
    event.target.value = '';
  };

  const onClickUploadFiles = async () => {
    if (files.length < 1) {
      console.error('Files are not selected on onClickUploadFiles');
      return;
    }

    // TODO 1. encrypt files
    // const encryptedFiles = await encryptFiles(files);
    // TODO 2. upload to aws
    // const uploadFilesInfo = await uploadFilesToAWS(
    //   encryptedFiles,
    //   setAbortControllers
    // );
    // TODO 3. add uploaded details to db(backend server)
  };

  const resetFiles = () => setFiles([]);

  return (
    <div>
      <Table files={files}></Table>

      <input type="file" multiple onChange={onSelectFileFromLoacl} />
      <button onClick={onClickUploadFiles}>Upload</button>
      <button onClick={resetFiles}>Reset</button>
    </div>
  );
};

export default Temp;
